<template>
  <div class="content">
          <div class="content-main">
  
              <!-- 上部 -->
              <div class="content-top">
                  <!-- 面包屑 -->
                  <div class="breadcrumbs">
                      <i></i>
                      <a href="/">首页</a><span>></span>
                      <a>关于我们</a><span>></span>
                      <a href="#" class="active">公司简介</a>
                  </div>
                  <!-- 侧边栏 -->
                <div class="sidebar" style="margin-right: 15px;">
                    <ul>
                        <li>
                            <div class="sidebar-title active">
                                <span>关于我们</span>
                            </div>
                            <!-- 子菜单 -->
                            <ul class="sidebar-health">   
                              <li id="gsjj"><a @click="gotoAbout('/about')">公司简介</a></li>
                              <li id="gsry" ><a @click="gotoAbout('/honor')">公司荣誉</a></li>
                              <li id="lxwm" class="health-active">联系我们</li>
                            </ul>
                        </li>
                        
                    </ul>
                </div>
                  <div class="top-mid f-cb fw" style="margin-bottom: 10px;">
                      <table style="width:100%;">
						  <tr>
							  <td style="width: 245px;vertical-align: top;">
								  <p class="title" style="margin-bottom: 20px;">联系我们</p>
								  <div class="content-mid" style="padding-top:0px;font-size:16px;width: 245px;" v-html="htmlContent">
								  </div>
							  </td>
							  <td>
								  <iframe src="https://www.yiyuekangyang.com/static/bdmap.html" style="width:100%;height:722px;overflow: hidden;border:0;" scrolling="no"></iframe>
							  </td>
						  </tr>
					  </table>
                  </div>
              </div>
              
          </div>
</div>
  <div style="clear:both;"></div>
  
  </template>
  
  <style src="../../../public/static/css/company-profile.css" />
  
  <style>
    .box{width:200px; height: 330px; float:left; margin:20px 10px; border: 1px solid #ccc;}
    .box img{width: 200px; height: 280px;}
    .box p{font-size: 14px; padding: 20px;background-color: #f6bf7d;}
    .box .imgdiv{background-color: #001259;}
	.fw{width:calc(100% - 207px);}
  </style>
  
  <script>
  
  import * as Api from '@/api'
  export default {
    data() {
      return {
        recordId: null,
        htmlContent: '',
        record: {}
      }
    },
    created() {
      this.recordId = 3
      this.loadData()
    },
    methods: {
      loadData() {
        Api.pageinfo({pageId: this.recordId}).then(response=>{
          console.log(response)
          this.record = response.data.data.detail
          this.htmlContent = this.record.content
        })
      }
    }
  }
  
  </script>